<template>
    <div>
        <rxNavBar title="工资详情" ></rxNavBar>
        <div class="types">
            <div class="typeDiv">
                <div class="typef" @click="popupDuty" id="downList">职务</div>
                <div :class="isDutyShow?'small-icon-rotate':'small-icon'"></div>                
            </div>
            <div class="content-divide-sa">|</div>
            <div class="typeDiv">
                <div class="order" @click="showStaff" id="downOrderList">员工</div>
                <div :class="isStaffShow?'small-icon-rotate':'small-icon'"></div>                
            </div>
            <div class="content-divide-ac">|</div>
            <div class="typeDiv">
                <div class="sif" @click="showIf" id="downIfList">条件筛选</div>
                <div :class="isIfShow?'small-icon-rotate':'small-icon'"></div>                
            </div>
        </div> 

        <!-- 职务搜索 -->
        <van-popup v-model="isDutyShow" position="bottom">
            <div class="searchDiv">
                <van-search
                placeholder="请输入"
                v-model="dutyName"
                size="large"
                @input="quaryDutyList()"
                />
            </div>
            <van-picker
                show-toolbar
                value-key="dutyName"
                :columns="dutyArr"
                @cancel="cancelDutyOption"
                cancel-button-text="重置"
                @confirm="selectDutyOption"
            />
        </van-popup>

        <!-- 员工搜索 -->
        <van-popup v-model="isStaffShow" position="bottom">
            <!--搜索栏-->
            <div class="searchDiv">
                <van-search
                placeholder="请输入"
                v-model="staffName"
                size="large"
                @input="quaryStaffList()"
                />
            </div>
            <van-picker
                show-toolbar
                value-key="staffName"
                :columns="staffArr"
                @cancel="cancelStaffOption"
                cancel-button-text="重置"
                @confirm="selectStaffOption"
            />
        </van-popup>

        <!-- 条件筛选 -->
        <van-popup class="screen" v-model="maskShow" position="right">
            <div style="height: 70%;overflow: scroll">
                <div class="screety">部门类型</div>
                <div class="sty">
                    <span class="stye"  @click="changenos() " :class="{'bgcolor':-1==spanIndex}">不限</span>
                    <span class="stye" v-for="( item,index) in departmentTypeList" :key="index" @click="changeSpan(index)"
                        :class="{'bgcolor':index==spanIndex}">{{ item.dictionaryTitle}}</span>
                </div>
                <div class="screety">员工状态</div>
                <div class="sty">
                    <span class="stye" @click="pricechangenos()" :class="{'bgcolor':-1==pricecSIdex}">不限</span>
                    <span class="styeprice" v-for="(item,index) in staffStatusList" :key="index"
                        @click="pricechangeSpan(index)" :class="{'bgcolor':index==pricecSIdex}">{{item.dictionaryTitle}}</span>
                </div>
                <div class="scrsty" style="width: 94%;position: absolute;bottom: 4%;z-index: 100">
                    <div style="display: flex;align-items: center;white-space: nowrap">
                        <div @click="cleanSpan()"><img class="clean-icon" src="../../../assets/images/4.png"></div>
                        <div class="clean_button" @click="cleanSpan()">清空</div>

                    </div>
                    <div class="screen_button" @click="screenSpan()">筛选</div>
                </div>
            </div>
        </van-popup>

        <div class="potential">
            <!-- 部门检索 -->
            <div class="module-small" @click="showProject">
                <span class="project-name">{{
                    departmentName != undefined && departmentId != ''
                    ? departmentName
                    : '部门检索'
                }}</span>
                <img class="drop-down-image" src="../../../assets/images/drop-down.png" />
            </div>
            <!--部门检索 下拉菜单-->
            <van-popup v-model="isShowProjectMenu" position="bottom" style="max-height: 100%;">
                <div style="background-color: #f7f8fa">
                    <div class="choose-company-text">选择公司</div>
                    <van-row justify="center" style="background-color: #f7f8fa">
                        <div v-for="(item, index) in companyList" :key="index">
                            <van-col span="8" offset="4" class="company-col-style"
                            ><div
                                :class="
                                checkedIndex == index
                                    ? 'company-col-checked-style'
                                    : 'company-text-style'
                                "
                                @click="selectCompany(item, index)"
                            >
                                {{ item.departmentName ? item.departmentName : '部门检索' }}
                            </div></van-col
                            >
                        </div>
                    </van-row>
                </div>
                <van-tree-select
                    :active-id.sync="activeId"
                    :main-active-index.sync="activeIndex"
                    :items="items"
                    @click-nav="selectArea"
                    @click-item="selectDepartment"
                />
                <div class="search">
                    <van-button
                        style="width: 2rem; color: orangered;border-color: orangered"
                        plain
                        type="primary"
                        @click="resetDepartmentInfo"
                    >清空</van-button>
                    <van-button
                        @click="searchDepartmentInfo"
                        style="width: 2.8rem;margin-left: 4rem"
                        color="linear-gradient(to right, #ff6034, #ee0a24)"
                        size="normal"
                    >筛选</van-button>
                </div>
            </van-popup>
            
            <p class="subtitle">工资详情</p>
            <!-- 数据详情 -->
            <div class="dataDetail">
                <div class="dataStaffName">
                    <div style="font-size:0.4rem;">姓名</div>
                    <div v-for="item in staffList" :key="item.id">{{ item.staffName }}
                    </div>
                </div>
                <div class="staffData">
                    <div class="dataDetailHeaderRow">
                        <div v-for="item,index in staffHeaderList" :key="index" class="dataDetailHeader">
                            {{ item }}</div>
                    </div>
                    <div v-for="item in staffList" :key="item.id" class="dataDetailRightDataRow">
                        <div class="dataDetailRightData">{{ item.employeeNumber }}</div>
                        <div class="dataDetailRightData">{{ item.departmentName }}</div>
                        <div class="dataDetailRightData">{{ item.entryTime }}</div>
                        <div class="dataDetailRightData">{{ item.departureTime }}</div>
                        <div class="dataDetailRightData">{{ item.dutyLevelName }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.basePay }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.baseSalary }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.trafficSubsidy }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.communicationSubsidy }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.seniority }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.addtion }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.outBonus }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.receiveBonus }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.afterPercentageAmount }}</div>
                      <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.inputPercentage }}</div>
                      <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.achievements }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.externalEoefficient }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.baseSalaryAssessment }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.kitchen }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.workCheck }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.newPayableAmount }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.socialSecurity }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.extraSubsidy }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.taxableSalary }}</div>

                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.mutualFund }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.tax }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.taxedAddition }}</div>
<!--                      <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.socialSecurity }}</div>   五险一金-->

<!--                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.payableAmount }}</div>-->
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.delayAmount }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.actualAmount }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.owedAmount }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.debtAmount }}</div>
                        <div class="dataDetailRightData dataDetailRightDataMoney">{{ item.salaryAmount }}</div>

                        <div class="dataDetailRightData">{{ item.delayStatus }}</div>
                        <div class="dataDetailRightData">{{ item.staffStatus }}</div>
                        <div class="dataDetailRightData" @click="itemBankClick(item)">查看银行卡信息</div>
                        <!-- <div class="dataDetailRightData" @click="itemClick(item.dictionaryDescription)">{{ item.dictionaryDescription }}</div>
                        <div class="dataDetailRightData" @click="itemClick(item.staffName)">{{ item.staffName }}</div>
                        <div class="dataDetailRightData" @click="itemClick(item.payCardCode)">{{ item.payCardCode }}</div>
                        <div class="dataDetailRightData" @click="itemClick(item.subBank)">{{ item.subBank }}</div> -->
                    </div>
                </div>
                <van-popup v-model="showHide" title="详细信息" class="bankInfo">
                    <div class="detailInfoTitle">银行卡信息：</div>
                    <div class="detailInfo">
                        <span>开户行：</span>
                        <span>{{bankInfo.dictionaryDescription}}</span>
                    </div>
                    <div class="detailInfo">
                        <span>户名：</span>
                        <span>{{bankInfo.staffName}}</span>
                    </div>
                    <div class="detailInfo">
                        <span>银行卡号：</span>
                        <span>{{bankInfo.payCardCode}}</span>
                    </div>
                    <div class="detailInfo">
                        <span>支行：</span>
                        <span>{{bankInfo.subBank}}</span>
                    </div>
                </van-popup>
            </div>
        </div>
    </div>
</template>

<script>
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import {
        NavBar, Search, DropdownMenu,Row, Col,  Checkbox, CheckboxGroup,Button,
        DropdownItem, Popup, Tag,Tabbar, TabbarItem,List,PullRefresh,Toast, Picker,TreeSelect,Popover
    } from 'vant';
    import Vue from 'vue';
    Vue.use(List,PullRefresh);
    Vue.use(Checkbox);
    Vue.use(CheckboxGroup);
    import {queryAuthLevelDepartment,queryStaffList,decorationDetailInit,queryBaseData,queyDutySelList,queryPayrollApprovalDetailList} from "../../../getData/getData";
    import {responseUtil,getStaffId,globaluserId,money} from "../../../libs/rongxunUtil";
export default {
    name: 'payslipDetail',
    components: {
        [Button.name]: Button,
        [NavBar.name]: NavBar,
        [Search.name]: Search,
        [Popup.name]: Popup,
        [Tag.name]: Tag,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [TabbarItem .name]: TabbarItem ,
        [Tabbar.name]: Tabbar,
        [List.name]:List,
        [PullRefresh.name]:PullRefresh,
        [Row.name]: Row,
        [Col.name]: Col,
        [Picker.name]: Picker,
        [TreeSelect.name]: TreeSelect,
        [Toast.name]:Toast,
        [Popover.name]:Popover,
        rxNavBar,
    },
    data(){
        return{
                        //搜索项
            // 下拉
            isDutyShow:false,  //职务
            dutyName:'',  ///筛选输入
            dutyArr:[],  //职务列表
            duty_id:'',
            isStaffShow:false,
            staffName:'',
            staffArr:[],
            staff_id:'',

            maskShow:false,  //条件搜索框
            isIfShow:false,
            staffStatusList:[],  //员工状态
            departmentTypeList:[],  //部门类型
            salaryStatusList:[],  //缓发状态
            addtionTypeList:[],  //补扣类型

            spanIndex:-1,
            pricecSIdex:-1,

            //部门检索
            isShowProjectMenu:false,  //部门搜索
            companyList: [],  //公司列表
            level:'',
            departmentId:'',
            departmentName:'',
            departmentAuthData: {},
            functionId: '391',
            checkedIndex: 0,
            activeId: -1,
            activeIndex: 0,
            departmentLevelType:'',
            staffStatus:'',
            items: [
                {
                    text: '浙江',
                    children: [
                        { text: '杭州', id: 1 },
                        { text: '温州', id: 2 },
                    ],
                },
                {
                    text: '辽宁',
                    children: [
                        { text: '沈阳', id: 3 },
                        { text: '大连', id: 4 },
                    ],
                },
                {
                    text: '江苏',
                    children: [
                        { text: '南京', id: 5 },
                        { text: '无锡', id: 6 },
                    ],
                },
            ],

            page:{currentPage:1,numberPage:50,},
            refreshing: false,
            loading : true,
            isShow: false,
            isEmptuyFlag:true,
            finished: false,
            flag:true,

            staffHeaderList:[
                '工号','部门','入职日期','离职日期','级别','(税前)[基本工资','底薪','交通补助','通讯补助','年资','税前补扣项','出房重奖','收房重奖','剩余佣金','徒弟抽成','收房佣金','外部系数','底薪考核','火房考核','考勤','应发金额]','(应税)[社保','应税金额','应税工资]',"(税后)[互助金",'税','税后补扣项','本月缓发','实发金额','历史欠款','本月欠款','最终发放]','缓发','状态','银行卡信息'
            ],

            staffList:[],
            payrollmanage_id:'',

            // showPopover:false,
            // actions:[],
            // popupContent:'',
            // show:false
            showHide:false,
            bankInfo:{},
        }
    },
    mounted() {
        this.page={currentPage:1,numberPage:50,}
        this.staffList =[]
        this.payrollmanage_id = this.$route.query.payrollmanage_id
        this.duty_id = this.$route.query.duty_id
        this.staff_id = this.$route.query.staff_id
        this.level = this.$route.query.level
        this.departmentId = this.$route.query.department_id
        this.departmentName = this.$route.query.departmentName
        this.departmentLevelType = this.$route.query.departmentLevelType
        this.staffStatus = this.$route.query.staffStatus
        //详情
        // this.initSatffList()
        //公司初始化
        this.queryAuthLevelDepartment()
        //职务
        this.quaryDutyList()
        //人员
        this.quaryStaffList()
        this.initDictionaryData()
    },
    methods:{
        itemBankClick(data){
            this.bankInfo.dictionaryDescription = data.dictionaryDescription
            this.bankInfo.staffName = data.staffName
            this.bankInfo.payCardCode = data.payCardCode
            this.bankInfo.subBank = data.subBank
            this.showHide = true
        },
        itemClick(data) {
            Toast(data);
        },

       //职务筛选
       popupDuty(){
            this.isDutyShow = true
        },
        quaryDutyList() {
            let that = this
            let data = {}
            data.searchName = that.dutyName
            //queryRentTenant  seeWithUserList
            queyDutySelList(data).then(function(response) {
                responseUtil.dealResponse(that, response, () => {
                    that.dutyArr = response.data.data.dutyList
                })
            })
        },
        cancelDutyOption(){
            this.page={currentPage:1,numberPage:50,}
            this.staffList =[]
            this.duty_id = ''
            this.initSatffList()
            this.isDutyShow = false
        },
        selectDutyOption(item) {
            this.page={currentPage:1,numberPage:50,}
            this.staffList =[]
            this.duty_id = item.id
            this.initSatffList()
            this.isDutyShow = false
        },

        //人员筛选
        showStaff(){
            this.isStaffShow = true
        },
        quaryStaffList(){
            let that = this
            let data = {}
            data.searchName = that.staffName
            //queryRentTenant  seeWithUserList
            queryStaffList(data).then(function(response) {
                responseUtil.dealResponse(that, response, () => {
                  that.staffArr=response.data.data.data
                })
            })
        },

        cancelStaffOption(){
            this.page={currentPage:1,numberPage:50,}
            this.staffList =[]
            this.staff_id = ''
            this.initSatffList()
            this.isStaffShow = false
        },
        selectStaffOption(item){
            this.page={currentPage:1,numberPage:50,}
            this.staffList =[]
            this.staff_id = item.id
            this.initSatffList()
            this.isStaffShow = false
        },


        //条件筛选
        showIf: function () {
            this.maskShow = true;
        },
        changenos: function () {
            this.spanIndex = -1;
        },
        changeSpan: function (index) {
            // console.log(index)
            this.spanIndex = index
        },
        pricechangenos: function (index) {
            this.pricecSIdex = -1;
        },
        pricechangeSpan: function (index) {
            this.pricecSIdex = index;
        },

        //清空
        cleanSpan: function () {
                this.spanIndex = -1;
                this.pricecSIdex = -1;

            },
        screenSpan: function () {
        this.page={currentPage:1,numberPage:50,}
        this.staffList =[]

            // 部门类型
            if( this.spanIndex!= -1) {
                this.departmentLevelType = this.departmentTypeList[this.spanIndex].id
            }else{
                this.departmentLevelType =''
            }
            // 员工状态
            if( this.pricecSIdex!= -1) {
                this.staffStatus = this.staffStatusList[this.pricecSIdex].dictionaryValue
            }else{
                this.staffStatus =''
            }
            //统计
            this.initSatffList()
            //详情
            this.maskShow = false;
        },

        //显示部门检索下拉菜单
        showProject() {
            this.isShowProjectMenu = true
        },

        initSatffList(){
            // salaryData
            var that = this
            let initData = {}
            initData.departmentLevelType = this.departmentLevelType  //部门类型
            initData.staffStatus = this.staffStatus  //员工状态
            initData.level = this.level
            initData.department_id = this.departmentId  //部门id
            initData.departmentName = this.departmentName
            initData.duty_id = this.duty_id  //职务
            initData.staff_id = this.staff_id  //员工
            initData.user_id = getStaffId()
            initData.payrollmanage_id = this.payrollmanage_id   //this.salaryHistory_id
            initData.currentPage=that.page.currentPage
            initData.numberPage=that.page.numberPage
            queryPayrollApprovalDetailList(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {  
                    let count = response.data.data.pageCount
                    let list=response.data.data.data
                    for (let i = 0; i < list.length; i++) {
                        list[i].lastDelayAmount = money(list[i].lastDelayAmount)
                        list[i].basePay = money(list[i].basePay)
                        list[i].baseSalary = money(list[i].baseSalary)
                        list[i].workCheck = money(list[i].workCheck)
                        list[i].newPayableAmount = money(list[i].newPayableAmount)
                        list[i].communicationSubsidy = money(list[i].communicationSubsidy)
                        list[i].trafficSubsidy = money(list[i].trafficSubsidy)
                        list[i].extraSubsidy = money(list[i].extraSubsidy)
                        list[i].taxableSalary = money(list[i].taxableSalary)
                        list[i].mutualFund = money(list[i].mutualFund)
                        list[i].seniority = money(list[i].seniority)
                        list[i].socialSecurity = money(list[i].socialSecurity)
                        list[i].addtion = money(list[i].addtion)
                        list[i].outBonus = money(list[i].outBonus)
                        list[i].receiveBonus = money(list[i].receiveBonus)
                        list[i].actualReAchievements = money(list[i].actualReAchievements)
                      list[i].afterPercentageAmount = money(list[i].afterPercentageAmount)
                      list[i].inputPercentage = money(list[i].inputPercentage)

                      list[i].achievements = money(list[i].achievements)
                        list[i].baseSalaryAssessment = money(list[i].baseSalaryAssessment)
                        list[i].kitchen = money(list[i].kitchen)
                        list[i].payableAmount = money(list[i].payableAmount)
                        list[i].tax = money(list[i].tax)
                        list[i].taxedAddition = money(list[i].taxedAddition)
                        list[i].delayAmount = money(list[i].delayAmount)
                        list[i].actualAmount = money(list[i].actualAmount)
                        list[i].owedAmount = list[i].owedAmount?money(list[i].owedAmount):0.00
                        list[i].debtAmount = list[i].debtAmount?money(list[i].debtAmount):0.00

                        list[i].salaryAmount = list[i].salaryAmount?money(list[i].salaryAmount):money(list[i].actualAmount)

                      list[i].externalEoefficient = money(list[i].externalEoefficient)
                        that.staffList.push(list[i])
                    }
                    if(that.refreshing){
                        that.refreshing = false
                    }
                    // 加载状态结束
                    that.loading = false;
                    that.page.currentPage++;
                    // 数据全部加载完成
                    if (that.staffList.length==0 || that.staffList.length >= count) {
                        that.finished = true;
                    }else{
                        that.initSatffList()
                    }
                })
            })
        },


        queryAuthLevelDepartment() {
            const that = this
            let initData = {}
            initData.staff_id = getStaffId() //员工id
            initData.function_id = that.functionId //方法模块id
            queryAuthLevelDepartment(initData).then(res =>
                responseUtil.dealResponse(that, res, () => {
                    that.departmentAuthData = res.data.data
                    let allList = that.departmentAuthData.list
                    if (allList != null) {
                        that.companyList = allList.filter(item => item.level == 1)
                    }
                    let departmentId = that.departmentAuthData.roleDepartmentId
                    that.departmentId = departmentId
                    let item = that.departmentAuthData.list.find(
                        item => item.id == departmentId
                    )
                    that.departmentName = item ? item.departmentName || '' : ''
                    that.initOtherDepartment()
                    that.searchDepartmentInfo()
                })
            )
        },
        initOtherDepartment() {
            let that = this
            let allList = that.departmentAuthData.list
            let departmentId = that.departmentId
            let threeLevelDepartments = allList.filter(item => item.level == 3)
            let fiveLevelDepartments = allList.filter(item => item.level == 5)
            let levelDepartments = threeLevelDepartments
                .filter(item => {
                    let levelTop = item.levelTop || ''
                    return levelTop.indexOf(departmentId) != -1
                })
                .map(item => {
                    let result = {}
                    result.children = fiveLevelDepartments
                        .filter(child => {
                            let levelTop = child.levelTop || ''
                            return levelTop.indexOf(',' + item.id) != -1
                        })
                        .map(child => {
                            let childResult = {}
                            childResult.text = child.departmentName
                            childResult.id = child.id
                            childResult.level = child.level
                            return childResult
                        })
                    result.text = item.departmentName
                    result.value = item.id
                    result.level = item.level
                  return result
                })
            that.items = levelDepartments // 公司列表
        },
        //选择公司，联动下方区、部
        selectCompany(item, index) {
            let that = this
            let roleLevel = this.departmentAuthData.roleLevel || 0
            let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
            if (roleDepartmentId != item.id && Number(item.level) < roleLevel) {
                this.isShowProjectMenu = false
                responseUtil.alertErrMsg(that, '权限不足')
                return
            }
            this.checkedIndex = index
            this.level = item.level
            this.departmentId = item.id
            this.departmentName = item.departmentName
          this.initOtherDepartment()
        },
        selectArea(value) {
            let item = this.items[value]
            let roleLevel = this.departmentAuthData.roleLevel || 0
            let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
            if (roleDepartmentId != item.value && Number(item.level) < roleLevel) {
                this.isShowProjectMenu = false
                responseUtil.alertErrMsg(this, '权限不足')
                return
            }
            this.level = item.level
            this.departmentId = item.value
            this.departmentName = item.text
        },
        selectDepartment(data) {
            let roleLevel = this.departmentAuthData.roleLevel || 0
            let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
            if (roleDepartmentId != data.value && Number(data.level) < roleLevel) {
                this.isShowProjectMenu = false
                responseUtil.alertErrMsg(this, '权限不足')
                return
            }
            this.level = data.level
          this.departmentId = data.id
            this.departmentName = data.text

        },
        searchDepartmentInfo() {
            this.page={currentPage:1,numberPage:50,}
            this.staffList=[]
            this.initSatffList()
            this.isShowProjectMenu = false
        },
        resetDepartmentInfo() {
            let departmentId = this.departmentAuthData.roleDepartmentId
            let item = this.departmentAuthData.list.find(
                item => item.id == departmentId
            )
            this.departmentName = item ? item.departmentName || '' : ''
            this.departmentId = departmentId
            this.isShowProjectMenu = false
            this.searchDepartmentInfo()
        },
        // 获取字典数据
        initDictionaryData: function() {
            var that = this
            let initData = {}
            initData.user_id = getStaffId()
            initData.dbName = ['departmentType','addTionType']
            initData.fdName = ['STAFFSTATUSMAP',"APPROVESTATUSMAP",'STAFFSALARYSTATUSMAP']
            queryBaseData(initData).then(function(response) {
                responseUtil.dealResponse(that, response, () => {
                    that.staffStatusList = response.data.data.STAFFSTATUSMAP
                    // that.approvalStatusList = response.data.data.APPROVESTATUSMAP
                    that.salaryStatusList = response.data.data.STAFFSALARYSTATUSMAP
                    that.departmentTypeList = response.data.data.departmentType
                    that.addtionTypeList = response.data.data.addTionType
                })
            })
        },



    }
}
</script>

<style lang="less" scoped>
    .types {
        width: 94%;
        // height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #FFFFFF;
        padding: 0.27rem;
        font-size: 12px;
    }

    .typeDiv{
        display: flex;
        align-items: center;
    }

    .typef {
        // width: 60px;
        text-align: right;
        height: 15px;
        font-size: 13px;
        color: #000000;
        font-weight: bold;
        // margin: 15px 0 0 18px;
    }

    .order {
        // width: 80px;
        text-align: right;
        height: 15px;
        font-size: 13px;
        color: #000000;
        font-weight: bold;
        // margin: 15px 0 0 10px;
    }

    .sif {
        width: auto;
        height: 15px;
        font-size: 13px;
        color: #000000;
        font-weight: bold;
        // margin: 15px 0 0 21px;
    }

    .small-icon {
        width: 8px;
        height: 8px;
        margin-top: 3px;
        margin-left: 2px;
        background-image: url("../../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .small-icon-rotate {
        width: 8px;
        height: 8px;
        margin-top: 3px;
        margin-left: 2px;
        background-image: url("../../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        transform: rotate(180deg);
    }

    .screen {
        width: 80%;
        height: 100%;
        padding-top: 1rem;

    }
    .screety {
        font-size: 13px;
        font-weight: bold;
        /*margin-left: 20px;*/
        /*margin-top: 25px;*/
      margin: 20px;
    }

    .scrsty {
      display: flex;
      align-items: center;
      justify-content: space-between;

    }

    /*垃圾桶*/
    .clean-icon {
        height: 20px;
        /*padding: 0 5px 0 23px;*/
      margin-right: 10px;
      margin-left: 10px;
        width: 16px;
    }

    /*清空*/
    .clean_button {
        /*position: absolute;*/
        /*top: 83px;*/
        font-size: 14px;
        color: rgba(255, 93, 59, 1);
    }

    /*筛选按钮*/
    .screen_button {
        /*position: absolute;*/
        /*left: 150px;*/
        /*padding: 11px 31px;*/
      width: 80px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
        font-size: 14px;
        font-weight: bold;
        border-radius: 6px;
        color: rgb(255, 255, 255);
        border: none;
        background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Firefox 3.6 - 15 */
        background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
    }

    /*遮罩*/
    .black_overlay {
        display: none;
        position: absolute;
        top: 38%;
        left: 0%;
        width: 100%;
        height: 62%;
        background-color: black;
        z-index: 1001;
        -moz-opacity: 0.8;
        opacity: .80;
        filter: alpha(opacity=88);
    }

    /*遮罩*/
    .black_order_overlay {
        display: none;
        position: absolute;
        top: 45%;
        left: 0%;
        width: 100%;
        height: 55%;
        background-color: black;
        z-index: 1001;
        -moz-opacity: 0.8;
        opacity: .80;
        filter: alpha(opacity=88);
    }


    .sty {
        margin-left: 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: start;
    }

    .stye {
        white-space: nowrap; /*强制span不换行*/
        display: inline-block; /*将span当做块级元素对待*/
        overflow: hidden; /*超出宽度部分隐藏*/
        text-overflow: ellipsis; /*超出部分以点号代替*/
        line-height: 0.9; /*数字与之前的文字对齐*/
        color: #999999;
        font-size: 12px;
        text-align: center;
        padding: 7px 12px;
      width: 24%;
        /*margin-right: 6px;*/
    }

    .styeprice {
        white-space: nowrap; /*强制span不换行*/
        display: inline-block; /*将span当做块级元素对待*/
        overflow: hidden; /*超出宽度部分隐藏*/
        text-overflow: ellipsis; /*超出部分以点号代替*/
        line-height: 0.9; /*数字与之前的文字对齐*/
        color: #999999;
        font-size: 12px;
        text-align: center;
        padding: 7px 12px;
      width: 24%;
        /*margin-right: 16px;*/
    }

    .bgcolor {
        background: rgb(255, 221, 220);
        color: rgb(255, 91, 62);
        font-size: 12px;
        text-align: center;
        border-radius: 5px;
        width: 24%;
      /*display: flex;*/
      /*align-items: center;*/
      /*justify-content: center;*/
    }
    .selectList {
        position: fixed;
        z-index: 9;
        width: 100%;
        height: 220px;
        top: 8%;
        padding: 5px 0 14px 0;
        background-color: white;
    }

    .select {
        width: 100%;
        height: 35px;
        line-height: 30px;
        font-size: 14px;
        text-align: left;
        margin-left: 15px;
        color: #999999;
    }

    .selectActive {
        color: #fe5e3a;
    }

    .reviews {
        width: 100%;
        overflow: visible;
        height: auto;
        position: absolute;
        top: 22%;
        background-color: #FFFFFF;
    }

    .content-divide-sa {
        font-size: 14px;
        /*margin: 15px 0 0 35px;*/
        color: #f4f4f4;
    }

    .content-divide-ac {
        font-size: 14px;
        /*margin: 15px 0 0 25px;*/
        color: #f4f4f4;
    }

    .content-divide-dos {
        font-size: 14px;
        margin: 15px 0 0 5px;
        color: #999999;
    }

    .keyAddress {
        font-size: 12px;
        /*height: auto;*/
        padding-top: 3px;
        /*padding-left: 17px;*/
        color: #7d7b7b;
    }

</style>


<style lang="less" scoped>
  .potential {
    width: 375px;
    background-color: rgb(250, 250, 250);
    padding: 15px 15px 0;
    box-sizing: border-box;
    overflow: hidden;
    color: #252525;

  }
/*项目选择模块*/
.module-small {
//   width: 92%;
  height: 51px;
  margin: 0 auto;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 8px;
  display: flex;
    align-items: center;
    justify-content: space-between;
}
/*项目名称样式*/
.project-name {
  font-size: 15px;
  color: #222222;
  margin-left: 20px;
  font-weight: bold;
}
/*下拉菜单右侧图标样式*/
.drop-down-image {
  float: right;
  margin-right: 0.5rem;

}
.choose-company-text {
  font-size: 0.4rem;
  font-weight: 700;
  color: black;
  padding: 10px 0 0 5px;
  font-size: 20px;
}
/*公司字体样式 未选中*/
.company-col-style {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/*公司字体样式 被选中*/
.company-col-checked-style {
  width: 2rem;
  text-align: center;
  background-color: #d3d3d3;
  border-radius: 0.1rem;
  font-size: 17px;
}
.search {
  margin: 15px 10px;
}
/*公司字体样式*/
.company-text-style {
  width: 2rem;
  text-align: center;
  /*background-color:#f7f8fa;*/
  border-radius: 0.1rem;
  font-size: 17px;
}
</style>
   
<style lang="less" scoped>
     
     .data-box{
        font-size:13px;
        margin:12px 0px;
        border:1px solid #fd7273;
        
        .th-row{
            height:30px;
            line-height:30px;
            padding:0px 12px;
            background:#fd7273;
        }
        .td-row{
            height:30px;
            line-height:30px;
            padding:0px 12px;
        }
        
    }
     
    // 超出 1 行显示省略号
    .common-ellipsis {
      width:80px;
      height: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
 
    // 滚动条位置 --展示的是竖向
    // /deep/.__bar-is-vertical {
    // right: -1px !important;
    // }
    // // 隐藏横向滚动条
    // /deep/.__bar-is-horizontal {
    // display: none !important;
    // }
    
    // 滚动条位置 --展示横向
    /deep/.__bar-is-vertical {
        display: none !important;
    }
    // 隐藏横向滚动条
    /deep/.__bar-is-horizontal {
        bottom: -1px !important;
    }
</style>


<style lang="less" scoped>
.potential{
    .subtitle {
      padding: 5px 0px;
      color: rgb(244, 99, 76);
      margin: 15px 0 0 0;
      font-weight: 700;
      font-size: 13px;
    }
    .dataDetail{   //数据
        width: 345px;
        font-size: 14px;
        padding: 10px 0;
        display: flex;
        border-radius: 0.2rem;
        background-color: #FFFFFF;
        .dataStaffName{  //左侧名字
            display: flex;
            flex-direction: column;
            >div:nth-of-type(1){
                font-weight: bolder;
            }
            >div:nth-of-type(even){
                    background-color: #fdebd5;
            }
            >div{
                padding: 0 10px;
                // font-size: 14px;
                width: 70px;
                height: 25px;
                line-height: 25px;
            }
        }
        .staffData{  //右侧数据
            width: 265px;
            // display: inline-block;
            overflow:hidden;
            white-space:nowrap;
            overflow-x: scroll;
            display: flex;
            flex-direction: column;

            .dataDetailHeaderRow{  //右侧表头横hang
                height: 25px;
                line-height: 27px;
              //.dataDetailHeader:nth-of-type(1),
                .dataDetailHeader:nth-of-type(33),.dataDetailHeader:nth-of-type(34){     //短两点
                    width: 70px;
                }
                .dataDetailHeader:nth-of-type(3),.dataDetailHeader:nth-of-type(4){     //短一点
                    width: 90px;
                }
                .dataDetailHeader{     //数据的每个表头
                    display:inline-block;
                    width: 120px;
                    font-size: 0.4rem;
                    font-weight: bolder;
                }
            }
            .dataDetailRightDataRow:nth-of-type(even){
                background-color: #fdebd5;
                >.dataDetailRightData{
                    background-color: #fdebd5;
                }
            }
            .dataDetailRightDataRow{  //右侧数据横hang
                height: 25px;
                line-height: 25px;
              //.dataDetailRightData:nth-of-type(1),
                .dataDetailRightData:nth-of-type(33),.dataDetailRightData:nth-of-type(34){     //短一点
                    width: 70px;
                }
                .dataDetailRightData:nth-of-type(3),.dataDetailRightData:nth-of-type(4){     //短一点
                    width: 90px;
                }
                .dataDetailRightData{     //右侧每个数据
                    display:inline-block;
                    width: 120px;
                    overflow: hidden;
                    font-size: 14px;
                    height: 25px;
                }
                .dataDetailRightDataMoney{
                    box-sizing: border-box;
                    padding: 0 50px 0 0;
                    text-align: right;
                }
            }
        }
        .bankInfo{
            box-sizing: border-box;
            width: 300px;
            padding: 20px;
            border-radius: 10px;
            .detailInfo{
                margin-left: 15px;
                font-size: 0.35rem;
                >span:nth-of-type(1){
                    display: inline-block;
                    width: 70px;
                }
            }
            .detailInfoTitle{
                margin-bottom: 5px;
                color: rgba(255, 93, 59, 1);
                font-size:0.43rem
            }
        }

    }   
}

</style>
